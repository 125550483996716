import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { TemplateStandardQuery } from '../../graphql-types';

const Standard = ({ data }: { data: TemplateStandardQuery }): JSX.Element => {
  const { markdownRemark } = data;
  if (!markdownRemark) {
    throw new Error('markdownRemark が取得できません。');
  }
  const { frontmatter, html } = markdownRemark;
  if (!html) {
    throw new Error('markdownRemark.html が取得できません。');
  }
  return (
    <Layout seo={{ title: frontmatter?.title ?? '' }}>
      <section className="section">
        <div
          className="container"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </section>
    </Layout>
  );
};
export default Standard;

export const pageQuery = graphql`
  query TemplateStandard ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
